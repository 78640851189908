.searchBox1 {
  position: absolute;
  position: fixed;
  background: #2f3640;
  height: 50px;
  border-radius: 40px;
  padding: 5px;

}

.searchBox1:hover > .searchInput1 {
  width: 150px;
  padding: 0 5px;
}

.searchBox1:hover > .searchButton1 {
background: white;
color : #2f3640;
}

.searchButton1 {
  color: white;
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #2f3640;
  transition: 0.4s;
}

.searchInput1 {
  
  border:none;
  background: none;
  outline:none;
  float:left;
  padding: 0;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  line-height: 40px;
  width: 0px;
} 

@media screen and (max-width: 620px) {
.searchBox1:hover > .searchInput1 {
  width: 100px;
  padding: 0 6px;
}
}

.Result1 {

  margin-top: 5px;
  max-height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  
}
.Result1::-webkit-scrollbar {
  display: none;
}

.Result1 .Item1 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
  text-align: center;
}
.Item1 p {
  margin-left: 10px;
}
.Item1:hover {
  background-color: lightgrey;
}